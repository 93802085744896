import React from 'react';

import { container, grid, sectionBox, radiusBox, form } from './client-password-change.module.scss';
import { ISection } from '../../models/section.model';

import Section from '../hoc/section';
import RadiusBox from '../hoc/radius-box';
import ChangePasswordForm from '../organisms/change-password-form';

export interface IClientPasswordChangeProps {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

const ClientPasswordChange: React.FC<IClientPasswordChangeProps> = ({
    className = '',
    section,
}) => {
    const { style, css, sectionId } = section;

    return (
        <Section
            className={`${sectionBox} ${className}`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            <div className={container}>
                <RadiusBox className={radiusBox}>
                    <ChangePasswordForm className={form} />
                </RadiusBox>
            </div>
        </Section>
    );
};

export default ClientPasswordChange;

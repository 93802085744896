import React from 'react';
import { SwiperOptions } from 'swiper';
import 'swiper/css';

import {
    section as sectionClass,
    overwrite,
    grid,
    title,
    item,
    link as linkClass,
    activeLink,
} from './subpage-menu.module.scss';
import { INavLink } from '../../models/nav-link.model';
import { ISection } from '../../models/section.model';
import { isBrowser } from '../../utils/is-browser';

import ConditionalLink from '../hoc/conditional-link';
import Section from '../hoc/section';
import Slider from '../hoc/slider';

export interface ISectionSubpageMenu extends ISection {
    items: {
        menuLinks: INavLink[];
    };
}

interface ISubpageMenuProps {
    className?: string;
    section: ISectionSubpageMenu;
}

const SubpageMenu: React.FC<ISubpageMenuProps> = ({ section }) => {
    const { menuLinks: receivedLinks } = section.items;

    const [firstLevelMenu, secondLevelMenu] = checkForDoubleMenu(receivedLinks);

    if (!firstLevelMenu) return null;

    const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';

    const initialIndex = firstLevelMenu?.links?.findIndex((subLink) =>
        subLink.value ? subLink.value === currentPath || currentPath.includes(subLink.value) : false
    );

    const breakpoints: { [p: number]: SwiperOptions; [p: string]: SwiperOptions } =
        firstLevelMenu.links
            ? {
                  0: {
                      slidesPerView: 2,
                  },
                  480: {
                      slidesPerView:
                          firstLevelMenu.links.length < 3 ? firstLevelMenu.links.length : 3,
                  },
                  720: {
                      slidesPerView:
                          firstLevelMenu.links.length < 4 ? firstLevelMenu.links.length : 4,
                  },
                  1024: {
                      slidesPerView:
                          firstLevelMenu.links.length < 5 ? firstLevelMenu.links.length : 5,
                  },
                  1400: {
                      slidesPerView: firstLevelMenu.links.length,
                  },
              }
            : {};

    const isActiveLinkInSubPages =
        !!firstLevelMenu.links && getIsActiveLinkInSubPages(firstLevelMenu.links);

    return (
        <>
            <Section
                className={`${sectionClass} ${overwrite}`}
                classes={{ container: grid }}
                sectionId={section.sectionId}
                style={section.style}
                css={section.css}
            >
                {firstLevelMenu.label !== '!' && (
                    <span className={title}>{firstLevelMenu.label}</span>
                )}
                <Slider breakpoints={breakpoints} initialSlide={initialIndex}>
                    {firstLevelMenu.links &&
                        firstLevelMenu.links.length > 0 &&
                        firstLevelMenu.links.map((link) => {
                            return (
                                <li className={item} key={`link-${link.linkId}`}>
                                    <ConditionalLink
                                        activeClassName={activeLink}
                                        {...(isActiveLinkInSubPages ? {} : { partiallyActive: true })}
                                        className={linkClass}
                                        type={link.type}
                                        to={link.value || ''}
                                        blank={false}
                                    >
                                        {link.label}
                                    </ConditionalLink>
                                </li>
                            );
                        })}
                </Slider>
            </Section>
            {secondLevelMenu && (
                <SubpageMenu section={{
                    ...section,
                    items: {
                        menuLinks: [secondLevelMenu]
                    }
                }} />
            )}
        </>
    );
};

function getIsActiveLinkInSubPages(links: INavLink[]) {
    if (!isBrowser()) return;
    const currentPathname = window.location.pathname;
    return links.some((link) => link.value === currentPathname);
}

function checkForDoubleMenu(links: INavLink[]):[INavLink | undefined, INavLink | undefined] {

    if(links[0].links && links[0].links.length > 0 && links[0].links[0].links && links[0].links[0].links.length > 0) {

        const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';

        const secondLevelMenu = links[0].links.find((link) => {
            return !!link.links?.find((subLink) => {
                return subLink.value
                    ? subLink.value === currentPath || currentPath.includes(subLink.value)
                    : false;
            });
        });

        const firstLevelMenu = links[0].links.map((link) => {
            const containsTarget = link.links?.find((subLink) => {
                return subLink.value
                    ? subLink.value === currentPath || currentPath.includes(subLink.value)
                    : false;
            });

            if(containsTarget) {
                return {
                    ...link,
                    value: containsTarget.value
                }
            }

            return {
                ...link,
                value: link.links ? link.links[0].value : '/'
            }
        });

        if(firstLevelMenu && secondLevelMenu) {
            return [
                {
                    ...links[0],
                    links: firstLevelMenu
                },
                {
                    ...secondLevelMenu,
                    label: '!',
                }

            ];
        }

        return [getDefaultMenu(links), undefined]
    } else {
        return [getDefaultMenu(links), undefined];
    }
}

function getDefaultMenu(menuLinks: INavLink[]) {
    const currentPath = typeof window !== 'undefined' ? window.location.pathname : '';

    return menuLinks.find((link) => {
        return !!link.links?.find((subLink) => {
            return subLink.value
                ? subLink.value === currentPath || currentPath.includes(subLink.value)
                : false;
        });
    });
}

export default SubpageMenu;
